<template>
  <v-app>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Chào mừng tới hệ thống quản lý của
        <a href="https://prep.vn/" target="blank">Prep.vn</a>
      </div>
    </b-alert>
    <!--end::Notice-->

    <!--    <b-form-file @change="onFileChange" placeholder="Chọn tệp tin hoặc cancel để xóa" accept="audio/*"></b-form-file>-->
    <!--    <v-btn color="green" outlined class="align-center" @click="sendAudio">Send</v-btn>-->
    <!--    <template v-if="karaoke_data">-->
    <!--      <Karaoke :file="karaoke_data"></Karaoke>-->
    <!--    </template>-->

<!--    <div style="width: 100%; background-color: #ffffff; border-color: #ffffff" class="p-2">-->
<!--      <p class="text-h6 font-weight-bold ">Công cụ giảm size audio</p>-->
<!--      <VAudioCompress-->
<!--        class="pt-2"-->
<!--        labelProp="Chọn audio muốn compress"-->
<!--      />-->
<!--    </div>-->
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/service/api.service";

export default {
  name: "dashboard",
  components: {
    // ImageCompression: () => import('@/components/image/V-ImageCompress'),
    // Karaoke: () => import('@/components/Audio/Karaoke'),
    // VAudioCompress: () => import("@/components/Audio/v-audio-compress"),
  },
  data() {
    return {
      karaoke_data: null,
      karaoke_file: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  created() {},
  methods: {
    onFileChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.karaoke_data = null;
        vm.karaoke_file = null;
        return;
      }
      vm.karaoke_file = file;
      let reader = new FileReader();
      reader.onload = (e) => {
        vm.karaoke_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    async sendAudio() {
      // let vm = this;
      let formData = new FormData();
      formData.append("file", this.karaoke_file);
      try {
        // vm.is_call_api = true;
        let res = await ApiService.post("test-speech-to-text", formData);
        if (res.status === 200) {
          console.log(res.data);
          // vm.is_call_api = false;
        }
      } catch (error) {
        // vm.is_call_api = false;
        console.log(error);
      }
    },

    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
